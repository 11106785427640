$(document).ready(function() {
	document.getElementById('to-top__wrapper').style.display = 'none';
	$('#loader').addClass('hidden');

	// check if smart banner exists

	// SmoothScroll init
	var scroll = new SmoothScroll('a[href*="#"]', {
		speed: 500,
		speedAsDuration: true,
		easing: 'easeInOutCubic'
	});

	let spy = new Gumshoe('a.navbar-link', {
		scrollDelay: true,
		navClass: 'active',
		selector: '[data-gumshoe] .navbar-link',
		offset: 200
	});
	// menu background-color toggler
	$('.q-and-a__wrapper').click(function(e) {
		$(this).toggleClass('--open');
	});

	// set height of q and a box

	$('.q-and-a__wrapper').each(function() {
		$(this).css('max-height', $(this).innerHeight());
	});

	// init slider
	$('.partners__slider').slick({
		slidesToShow: 5,
		slidesToScroll: 5,
		dots: true,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 2576,
		adaptiveHeight: true,
		variableWidth: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					arrows: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}
		],
		prevArrow: `<div class = "slick-prev">
                <img src = "/assets/img/icons/arrow-left.svg">
            </div>`,
		nextArrow: `<div class = "slick-next">
                <img src = "/assets/img/icons/arrow-right.svg">
            </div>`
	});

	$('.packages__footer').click(function() {
		if ($(this).hasClass('--no-open') == false) {
			$(this)
				.prev()
				.parent()
				.toggleClass('--open');
		}
	});
	$('.main-section__smiley').mouseenter(function(e) {
		e.stopPropagation();
		console.log('mouseenter');
		$(this).addClass('bounce');
	});
	var modalTimeline = new TimelineMax({ paused: true });

	// pricing calclulator
	$('.btn-calculator').on('click', function() {
		// setTimeout(() => {
		//     var calcTarget = $(this).data("target-calc");
		//     initCalculator(calcTarget);
		// }, 500);
		var calcTarget = $(this).data('target-calc');
		initCalculator(calcTarget);
		// setTimeout sluzi da se modal pokrene nakon sto se obavi skrol to vrha elementa jer se u smoothscroll pluginu namesteno da se svaki scroll obavi u 500ms
	});
	$('.calculator__close-icon').click(function() {
		modalTimeline.reverse(0).timeScale(2);
	});
	$('.calculator').click(function(event) {
		//if you click on anything except the modal itself or the "close modal" link, close the modal
		if (
			!$(event.target).closest('.calculator__wrapper').length &&
			$(event.target).is('.calculator')
		) {
			// if (!$(event.target).closest(".calculator__wrapper").length) {
			modalTimeline.reverse(0).timeScale(2);
		}
	});
	function initCalculator(calcTarget) {
		modalTimeline.clear();
		modalTimeline.add(
			TweenMax.fromTo(
				'.calculator',
				0.3,
				{ display: 'none', autoAlpha: 0 },
				{ display: 'flex', autoAlpha: 1 }
			)
		);
		modalTimeline.add(
			TweenMax.fromTo(
				`.calculator__wrapper[data-calc='${calcTarget}']`,
				1.1,
				{ autoAlpha: 0, y: -400, display: 'none' },
				{
					autoAlpha: 1,
					y: 0,
					display: 'block',
					ease: Elastic.easeOut.config(1.5, 0.5)
				}
			)
		);
		modalTimeline.restart();
	}
});

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = debounce(function scrollFunction() {
	if (
		document.body.scrollTop > window.screen.height - 150 ||
		document.documentElement.scrollTop > window.screen.height - 150
	) {
		document.getElementById('to-top__wrapper').style.display = 'block';
		document.getElementById('demo-navbar').classList.add('--active');
	} else {
		document.getElementById('to-top__wrapper').style.display = 'none';
		document.getElementById('demo-navbar').classList.remove('--active');
	}
}, 17);
// When the user clicks on the button, scroll to the top of the document
function topFunction() {
	document.body.scrollTop = 0; // For Safari
	document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this,
			args = arguments;
		clearTimeout(timeout);
		timeout = setTimeout(function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		}, wait);
		if (immediate && !timeout) func.apply(context, args);
	};
}
// Log scroll events
var closeMenu = function(event) {
	$('html').removeClass('nav-open');
	window.gsdk.misc.navbar_menu_visible = 0;
	$('#bodyClick').remove();
	//  setTimeout(function(){
	//     $toggle.removeClass('toggled');
	//  }, 400);
};

// change back-to-top position when banner is removed

$('.smartbanner__exit').click(function() {
	$('#to-top__wrapper').removeClass('--banner__on-page');
});

// Listen for scroll events
// document.addEventListener('scrollStart', logScrollEvent, false);
document.addEventListener('scrollStop', closeMenu, false);
// document.addEventListener('scrollCancel', logScrollEvent, false);

// $(".btn-price").on('click', function(e){
//     e.preventDefault();
//     console.log('123')
//     $(".calculator__result").text("3000");
// });

// calculate final price
var textValue;
$('#calculate,#calculate-2').click(function() {
	// check which calc is selected
	var calcType = $(this).data('calc');
	if (calcType == 'classic') {
		var passCheck = false;
		var numberOfDevices = $('#classicDevicesNumber').val();
		var numberOfPeople = $('#classicPeopleNumber').val();
		$('.calculator__error-text').removeClass('-active');
		// check values
		if (numberOfPeople > 10000) {
			$('#classic__left-input')
				.toggleClass('-active')
				.text('Pošaljite upit na offce@4like.rs');
			passCheck = false;
		}
		if (numberOfPeople <= 100) {
			$('#classic__left-input')
				.toggleClass('-active')
				.text('Minimum je 100 ljudi.');
			passCheck = false;
		}
		if (numberOfDevices <= 0) {
			$('#classic__right-input')
				.toggleClass('-active')
				.text('Morate uneti broj uređaja.');
			passCheck = false;
		}
		if (numberOfDevices > 0) {
			passCheck = true;
		}
		if (numberOfDevices > 30) {
			$('#classic__right-input')
				.toggleClass('-active')
				.text('Pošaljite upit na offce@4like.rs');
			passCheck = false;
		}
		if (numberOfPeople > 100 && numberOfDevices <= 30) {
			var price = calcPrice(numberOfDevices);
			var finalPrice = calcFinalPrice(
				numberOfPeople,
				numberOfDevices,
				price
			);
			$('#classicResult').text(finalPrice);
		} else {
			$('#all-in-one__result, #classicResult').text('0.00');
		}
	} else if (calcType == 'all-in-one') {
		$('.calculator__error-text').removeClass('-active');
		var passCheck = false;
		var numberOfDays = textValue;
		var numberOfDevices = $('#all-in-one__right').val();
		if (numberOfDays == 'Više od 30') {
			$('#all-in-one__left-text')
				.toggleClass('-active')
				.text('Pošaljite upit na offce@4like.rs');
			passCheck = false;
		} else {
			passCheck = true;
		}
		if (numberOfDevices < 1) {
			$('#all-in-one__right-text')
				.toggleClass('-active')
				.text('Minimum je 1 uređaj');
			passCheck = false;
		} else if (numberOfDevices > 30) {
			$('#all-in-one__right-text')
				.toggleClass('-active')
				.text('Pošaljite upit na offce@4like.rs');
			passCheck = false;
		} else {
			passCheck = true;
		}
		if (
			numberOfDays != 'Više od 30' &&
			numberOfDevices >= 1 &&
			numberOfDevices <= 30
		) {
			var discount = calcDiscount(numberOfDevices);
			price = calcAllInOne(numberOfDays, numberOfDevices, discount);
			$('#all-in-one__result').text(price);
		} else {
			$('#all-in-one__result, #classicResult').text('0.00');
		}
	}
});
function selectValue(element, e) {
	event.stopPropagation();
	textValue = element.innerText;
	$('.btn-text').html(`
        ${textValue}
        <b class="caret"></b>
        <ul class="dropdown-menu dropdown-menu-left" id = "all-in-one-select">
            <li><a class = "all-in-one-option" onclick = "selectValue(this, event)" href="#1">3</a></li>
            <li><a class = "all-in-one-option" onclick = "selectValue(this, event)" href="#1">7</a></li>
            <li><a class = "all-in-one-option" onclick = "selectValue(this, event)" href="#1">10</a></li>
            <li><a class = "all-in-one-option" onclick = "selectValue(this, event)" href="#1">30</a></li>
            <li><a class = "all-in-one-option" onclick = "selectValue(this, event)" href="#1">Više od 30</a></li>
        </ul>
    `);
	let elem = document.getElementById('dropdown');
	elem.classList.remove('open');
}
function calcPrice(numberOfDevices) {
	var price;
	if (numberOfDevices >= 1 && numberOfDevices <= 3) {
		price = 999;
	} else if (numberOfDevices >= 4 && numberOfDevices <= 9) {
		price = 899;
	} else if (numberOfDevices >= 10 && numberOfDevices <= 19) {
		price = 699;
	} else if (numberOfDevices >= 20 && numberOfDevices <= 30) {
		price = 300;
	}
	return price;
}
function calcFinalPrice(numberOfPeople, numberOfDevices, price) {
	var final;
	if (numberOfPeople < 500) {
		final = 2990 + numberOfDevices * price;
	} else if (numberOfPeople >= 500 && numberOfPeople < 1000) {
		final = 4490 + numberOfDevices * price;
	} else if (numberOfPeople >= 1000 && numberOfPeople < 3000) {
		final = 7490 + numberOfDevices * price;
	} else if (numberOfPeople >= 3000 && numberOfPeople < 5000) {
		final = 9990 + numberOfDevices * price;
	} else if (numberOfPeople >= 5000 && numberOfPeople < 10000) {
		final = 14990 + numberOfDevices * price;
	}
	return final;
}
function calcDiscount(numberOfDevices) {
	var discount;
	if (numberOfDevices >= 1 && numberOfDevices <= 3) {
		discount = 1;
	} else if (numberOfDevices >= 4 && numberOfDevices <= 10) {
		discount = 0.8;
	} else if (numberOfDevices >= 11 && numberOfDevices <= 15) {
		discount = 0.7;
	} else if (numberOfDevices >= 16 && numberOfDevices <= 30) {
		discount = 0.6;
	}
	return discount;
}
function calcAllInOne(numberOfDays, numberOfDevices, discount) {
	var price;
	if (numberOfDays == 3) {
		price = 10773 * numberOfDevices * discount;
	} else if (numberOfDays == 7) {
		price = 19551 * numberOfDevices * discount;
	} else if (numberOfDays == 10) {
		price = 23940 * numberOfDevices * discount;
	} else if (numberOfDays == 30) {
		price = 35910 * numberOfDevices * discount;
	}
	return price;
}
